import { css } from "@emotion/core"
import submit from "../images/submit.gif"
import arrived from "../images/arrived.png"
import departed from "../images/departed.png"
import open from "../images/open.gif"
import closed from "../images/closed.gif"
import iconLink from "../images/icon_link.png"

export const sidebarStyle = css`

/* tools */
  @media (max-width: 820px) {
      width:100%; 
    }
  #searchTitle {
    @media (max-width: 820px) {
      display:none;
      
    }
  }
  input.searchSubmit {
    @media (max-width: 820px) {
      display:none;
    }
    background: none;
    padding: 0;
    border: none;
    background: transparent url(${submit}) no-repeat;
    width: 23px;
    height: 21px;
    padding: 0;
    min-width: 10px !important;
    margin-left: 0px !important;
    vertical-align: bottom;
  }

  input.search {
    @media (max-width: 820px) {
      display:none;
    }
    width: 85%;
    border-width: 1px;
    border: 1px solid #ababab;
    border-top-color: rgb(171, 171, 171);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(171, 171, 171);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(171, 171, 171);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(171, 171, 171);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 2px 5px 2px 5px;
    color: #444;
  }

  h2 {
    font-size: 1em;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-top: 0;
  }

  .lc {
    text-transform: lowercase;
  }

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: #9abdcb 1px dashed;
    }
    a {
      display: block;
      line-height: 1.2em;
      padding: 0.7em 0 0.8em 0;
    }
  }

  

  #tools {
    display: block;
    float: right;
    margin: 0;
    padding: 0 10px;
    width: 100%;
    #search {
      margin-bottom: 10px;
    }
    #search h2 {
      margin: 0 0 3px 0;
    }

    h2 a {
      display: block;
      width: auto;
      margin: 0;
      padding: 0.6em 0 0.3em 0;
      border-top: #9abdcb 1px dashed;
      text-decoration: underline;
    }
    #tides h2 a {
      border-top: none;
    }
    #tides,
    #weather {
      padding-bottom: 0px;
    }
    ul li {
      border: none;
    }
    @media (max-width: 820px) {
      padding-top: 10px;
      border-bottom: 1px dashed rgb(154, 189, 203);
    }
    @media (max-width: 610px) {
     padding:0 0 0 10px;
    }
  }

  #arrivals {
    background: #084369;
    color: #fff;
    box-sizing: border-box;
    padding: 5px 10px;
    float:left;
    width:100%;


    h2 {
      margin-top: 0;
      a,
      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: #fff;
        border: 10px;
        padding-left: 0;
      }
    }
    li {
      color: #fff;
      list-style-type: none;
    }
    li.arrive,
    li.depart {
      font-size: 0.9em;
      background: transparent url(${arrived}) no-repeat 1px 2px;
      padding-left: 28px;
      margin-top: 3px;
      min-height: 45px;
      display: block;
      width:100%;
    }
    li.depart {
      background: transparent url(${departed}) no-repeat 0 2px;
    }
    @media (max-width: 820px) {
      display: block;
      padding: 0px 10px;
      width: 33%;
     
      min-height: 175px;
      border-top: 0;
      li.arrive, li.depart {
        display:block;
      }
    }
    @media (max-width: 620px) {
      width:100%;
      min-height: 145px;
      li.arrive, li.depart {
        display:block;
      }
    }
   
  }

  #tides {
    float:left;
    p.gatesOpen,
    p.gatesClosed {
      padding-left: 28px;
      background: transparent url(${open}) no-repeat;
      text-decoration: none;
    }
    p.gatesClosed {
      background: transparent url(${closed}) no-repeat;
    }
    @media (max-width: 820px) {
      width: 33%;
      padding: 0 10px;
      float: left;
      min-height: 145px;
      border-top: 0;
    }
    @media (max-width: 619px) {
      width: 50%;
      margin-left:0;
      padding: 0;
    }
  }

  #weather {
    float: left;
   


    span {
        display:block;
        @media (min-width: 720px) {
            display:inline;
        }
    }
    ul li {
      font-size: 0.9em;
      display: inline;
      white-space: nowrap;
    }
    h2 a {
      padding-left: 28px;
      min-height: 30px;
      background: transparent url(${iconLink}) no-repeat 0 8px;
    }
    .today {
      overflow: hidden;
      img {
        float: left;
        padding-right: 5px;
      }
      p {
        margin: 0px 0px 5px 45px;
        padding-top: 5px;
      }
    }
    .today p .temperature {
      font-size: 1.4em;
      color: #0099cc;
      font-weight: bold;
    }
    @media (max-width: 820px) {
      width: 33%;
      padding: 0 10px;
      float: left;
      min-height: 145px;
      border-top: 0;
      border-left: 1px dashed rgb(154, 189, 203);
      margin-bottom: 5px;
      h2 a {
        border-top: 0;
      }
    }
    @media (max-width: 619px) {
      width: 50%;
      margin-left:0;
    }
  }
 
`
